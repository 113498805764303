/**
 * Created by gschulz on 27.02.14.
 */

let UrlHelper = (function () {

    let _private = {};

    _private.instance = this;

    _private.basePath = '';

    _private.initState = {
        pathname: '', hash: '', id: null, title: '', params: {},
        route:    {lang: '', module: '', controller: '', action: ''}
    };

    /**
     *
     * @param {boolean} [force]
     * @returns {UrlHelper}
     */
    this.init = function (force) {
        let initState = undefined;
        if (!force) {
            initState = CacheStorage.getCacheByKey('UrlHelper.initState', null);
        }
        if (!_.isNull(initState)) {
            _private.initState = initState;
            return this;
        }
        _private.initState.pathname = currentUrl;
        _private.initState.hash     = window.location.hash;
        _private.initState.title    = document.title;
        _private.parseUrl();
        CacheStorage.setCacheByKey('UrlHelper.initState', _private.initState);
        return this;
    };

    /**
     *
     * @param {object} options
     * @param {boolean} [replace]
     */
    this.setState = function (options,replace = false) {
        if (!_.isObject(options)) {
            options = {};
        }
        options            = $.extend({}, jQuery.extend(true, {}, _private.initState), options);
        let url            = _private.getBaseUrl() + _private.getParamsString(options.params) + location.hash;
        _private.initState = options;
        if(replace){
            return History.replaceState(options.id, options.title, url);
        }
        History.pushState(options.id, options.title, url);
    };

    /**
     *
     * @param {object} options
     * @param {boolean} [resetParams]
     * @returns {*}
     */
    this.getUrl = function (options, resetParams) {
        resetParams = _.isUndefined(resetParams) ? true : resetParams;
        let state   = jQuery.extend(true, {}, _private.initState);
        if (resetParams) {
            state.params = {};
        }
        options = $.extend(true, state, options);
        return (_private.getBaseUrl(options.route) + _private.getParamsString(options.params));
    };

    /**
     *
     * @param {string} [path]
     * @param {string} [hash]
     * @returns {{}}
     */
    this.parseUrlParams = function (path, hash) {
        let params = {};
        path       = path || _private.initState.pathname;
        hash       = hash || _private.initState.hash;
        if (history.pushState) {
            let pathParts = path.split('/');
            if (pathParts.length > 5) {
                for (let i = 5; i < pathParts.length; i += 2) {
                    params[pathParts[i]] = pathParts[i + 1];
                }
            }
        } else {
            if (hash !== '') {
                let hashParts = hash.split('/');
                delete hash[0];
                hashParts = '/' + hashParts.join('/');
                hashParts = hashParts.split('?')[0].split('/');
                for (let i = 0; i < hashParts.length; i += 2) {
                    params[hashParts[i]] = hashParts[i + 1];
                }
            }
        }
        return params;
    };

    this.updateParams = function () {
        _private.initState.params = _private.instance.parseUrlParams(location.pathname, location.hash);
    };

    /**
     *
     * @returns {*}
     */
    this.getParams = function () {
        return jQuery.extend(true, {}, _private.initState.params);
    };

    /**
     *
     * @returns {{route: {lang: string, module: string, controller: string, action: string}, params: {}}}
     */
    this.getState = function () {
        return jQuery.extend(true, {
            route:  {
                lang: '', module: '', controller: '', action: ''
            },
            params: {}
        }, _private.initState);
    };

    /**
     *
     * @param {string|undefined} [controller]
     * @param {string|undefined} [action]
     * @param {array|object} [params]
     * @returns {*}
     */
    this.getSimpleUrl = function (controller, action, params) {
        if (_.isUndefined(params)) {
            params = [];
        }
        let state    = _private.instance.getState();
        state.params = params;
        if (_.isString(controller)) {
            state.route.controller = controller;
        }
        if (_.isString(action)) {
            state.route.action = action;
        }
        return this.getUrl(state);
    };

    this.setBasePath = function (basePath) {
        _private.basePath = basePath;
        return this;
    };

    this.getBasePath = function () {
        return _private.basePath;
    };

    _private.parseUrl = function () {
        let path                            = _private.initState.pathname;
        path                                = path.substring(_private.basePath.length);
        let pathParts                       = path.split('/');
        _private.initState.route.lang       = pathParts[1];
        _private.initState.route.module     = pathParts[2];
        _private.initState.route.controller = pathParts[3];
        _private.initState.route.action     = pathParts[4];
        _private.initState.params           = _private.instance.parseUrlParams();
        History.options.disableSuid         = true;
    };

    /**
     *
     * @param {object} [route]
     * @returns {string}
     */
    _private.getBaseUrl = function (route) {
        route   = route || jQuery.extend(true, {}, _private.initState.route);
        let url = '/';
        $.each(['lang', 'module', 'controller', 'action'], function (i, key) {
            url += route[key] + '/'
        });
        url = url.substring(0, url.length - 1);
        return _private.basePath + url;
    };

    /**
     *
     * @param {object} params
     * @returns {*}
     */
    _private.getParamsString = function (params) {
        let url = $.map(params, function (v, k) {
            return k + '/' + v;
        }).join('/');
        if (url === '') {
            return '';
        }
        return '/' + url;

    };

});